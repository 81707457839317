.top-info .market:hover .tooltip-market {
    display: block;
}

.top-info .tooltip-market {
    display: none;
}

.top-info .tooltip-gas {
    display: none;
}

.top-info .gas:hover .tooltip-gas {
    display: block;
}

.author {
    background: var(--text-color-green);
}

.data-from {
    background: var(--text-color-purple);
}

/* responsive */
@media (max-width: 768px) {
    .top-info {
        height: auto !important;
        overflow: visible !important;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        text-align: center;
    }
}
