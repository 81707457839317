.top-chart {
    font-family: "Roboto", sans-serif;
}

@media only screen and (max-width: 640px) {
    .latest-trade {
        height: 20rem;
    }
}

.switcher-group .active {
    color: var(--arbitrum-blue) !important;
    background: none;
}

.range-switcher:hover {
    background: var(--main-card-color);
}

@media screen and (max-width: 1024px) {
    .top-chart {
        height: auto;
    }
}

.active-long {
    color: var(--main-long);
    line-height: 7vh;
    border-top: 2px solid var(--main-long);
    font-weight: 500;
    background: rgba(127, 212, 130, 0.05);
}

.active-short {
    color: var(--main-short);
    border-top: 2px solid var(--main-short);
    font-weight: 500;
    line-height: 7vh;
    background: rgba(161, 69, 69, 0.05);
}
