.dd-wrapper {
    position: relative;
}

.dd-wrapper .dd-list .dd-list-item:hover {
    color: var(--arbitrum-blue);
}

.dd-wrapper .dd-list-item svg:hover {
    fill: #fff !important;
}

.dd-wrapper .close {
    opacity: 0;
    transform: scaleY(0);
    animation: growUp 300ms ease-in-out forwards;
    transform-origin: top;
}

.dd-wrapper .open {
    opacity: 1;
    transform: scale(1);
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
}

.dd-wrapper .dd-list {
    position: absolute;
    top: 4rem;
    left: 0;
    width: auto;
    height: auto;
    overflow: hidden;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
    z-index: 9999999;
}

.select-custom .group-tooltip .tooltip {
    display: none;
}

.select-custom .group-tooltip:hover .tooltip {
    display: block;
    position: absolute;
    top: -2rem;
    left: 0.5rem;
    font-size: small;
}

.select-custom .right-action {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateX(-50%, -50%);
}

@keyframes growUp {
    0% {
        transform: scaleY(1);
    }

    80% {
        transform: scaleY(1.1);
    }

    100% {
        transform: scaleY(0);
    }
}

@keyframes growDown {
    0% {
        transform: scaleY(0);
    }

    80% {
        transform: scaleY(1.1);
    }

    100% {
        transform: scaleY(1);
    }
}
