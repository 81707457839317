.btn {
    letter-spacing: 0px;
    box-sizing: border-box;

    transition: box-shadow .15s, opacity .15s;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    will-change: box-shadow, opacity;
}

.btn-box-shadow {
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
}

.btn:hover {
    opacity: 0.8;
}

.secondary {
    background: linear-gradient(110deg, #2193a7 25%, #189fd9, #1e84df 50%, #232374 85%);
    border-radius: 4px;
}

.secondary:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.secondary:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(-2px);
}

/* .secondary:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
} */