.slippage-custom {
    position: relative;
}

.slippage-custom .group-tooltip .tooltip {
    display: none;
}

.slippage-custom .group-tooltip:hover .tooltip {
    display: block;
    position: absolute;
    top: -2rem;
    left: 0.5rem;
    font-size: small;
}

.slippage-custom .slippage-dd {
    position: absolute;
    top: 2.5rem;
    right: 0;
    width: 300px;
    background: var(--main-card-color-secondary);
    transition: all 0.3s ease-in-out;
    z-index: 9999999;
}

.slippage-custom .slippage-dd .slippage-dd-list .slippage-dd-item:hover {
    border: 1px solid var(--arbitrum-blue);
    color: var(--arbitrum-blue);
}

.slippage-custom .slippage-dd .slippage-dd-list .slippage-dd-item input {
    padding: 1px 2px;
    font-size: unset;
}

.slippage-custom .close {
    opacity: 0;
    transform: scaleY(0);
    animation: growUp 300ms ease-in-out forwards;
    transform-style: flat;
    transform-origin: top;
}

.slippage-custom .open {
    opacity: 1;
    animation: growDown 300ms ease-in-out forwards;
    transform-style: flat;
    transform-origin: top center;
}

@keyframes growDown {
    0% {
        transform: scaleY(0);
    }

    80% {
        transform: scaleY(1.1);
    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes growUp {
    0% {
        transform: scaleY(1);
    }

    80% {
        transform: scaleY(1.1);
    }

    100% {
        transform: scaleY(0);
    }
}
