.pair-token {
    position: relative;
}

.pair-token .label {
    color: #fff;
}

.pair-token .list {
    position: absolute;
    top: 2.5rem;
    left: 0;
    height: auto;
    width: auto;
    z-index: 999;
    transition: all 0.3s ease-in-out;
}

.bg-line {
    background: rgba(163, 165, 196, 0.2);
}

.pair-token .close {
    opacity: 0;
    transform: scaleY(0);
    animation: growUp 300ms ease-in-out forwards;
    transform-style: flat;
    transform-origin: top;
}

.pair-token .open {
    opacity: 1;
    animation: growDown 200ms ease-in-out forwards;
    transform-style: flat;
    transform-origin: top center;
}

.pair-token .active {
    color: var(--text-default);
    border: 1px solid var(--text-default);
}

@keyframes growDown {
    0% {
        transform: scaleY(0);
    }

    80% {
        transform: scaleY(1.1);
    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes growUp {
    0% {
        transform: scaleY(1);
    }

    80% {
        transform: scaleY(1.1);
    }

    100% {
        transform: scaleY(0);
    }
}

@media screen and (min-width: 768px) {
    .pair-token .list {
        width: 30rem;
    }
}
