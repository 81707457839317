.vault-card {
    height: 30rem;
}

.vault-card .capacity {
    background-color: #25252a;
}

.vault-card .vault-card-header .btn {
    background: #536bc7;
}

.bg-eth {
    background-image: url("../../img/bg/etherium-bg.jpg");
    background-size: cover;
}

.bg-btc {
    background-image: url("../../img/bg/btc-bg.jpg");
    background-size: cover;
}

.bg-doge {
    background-image: url("../../img/bg/doge-bg.jpg");
    background-size: cover;
}

.bg-usdc {
    background-image: url("../../img/bg/usdc-bg.jpg");
    background-size: cover;
}

.bg-pepe {
    background-image: url("../../img/bg/pepe-bg.jpg");
    background-size: cover;
}

.bg-hover-btc {
    transition: box-shadow 0.25s ease-out 0s, border 0.25s ease-out 0s;
}

.bg-hover-eth {
    transition: box-shadow 0.25s ease-out 0s, border 0.25s ease-out 0s;
}

.bg-hover-doge {
    transition: box-shadow 0.25s ease-out 0s, border 0.25s ease-out 0s;
}

.bg-hover-usdc {
    transition: box-shadow 0.25s ease-out 0s, border 0.25s ease-out 0s;
}

.bg-hover-btc:hover {
    border: 1px solid #3b2618;
    box-shadow: #3b2618 0px 13px 27px -5px, #3b2618 0px 8px 16px -8px;
}

.bg-hover-doge:hover {
    border: 1px solid #c4802d;
    box-shadow: #c4802d 0px 13px 27px -5px, #c4802d 0px 8px 16px -8px;
}

.bg-hover-eth:hover {
    border: 1px solid #7848b8;
    box-shadow: #7848b8 0px 13px 27px -5px, #7848b8 0px 8px 16px -8px;
}

.bg-hover-usdc:hover {
    border: 1px solid #0164ed;
    box-shadow: #0164ed 0px 13px 27px -5px, #0164ed 0px 8px 16px -8px;
}

.bg-hover-pepe:hover {
    border: 1px solid #025236;
    box-shadow: #025236 0px 13px 27px -5px, #025236 0px 8px 16px -8px;
}