@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap");

html,
body {
    min-height: 100%;
    width: 100%;
    color: white;
    font-family: "Poppins", sans-serif;
}

* {
    box-sizing: border-box;
}

:root {
    --main-bg-color: #0c0f13;
    --main-card-color: #1b2028;
    --bg-card: #0a121e;
    --input-bg-color: #071223;
    --main-long: #16be76;
    --main-short: #e43e53;
    --main-yellow: #dbe765;
    --text-color-green: #84cc16;
    --text-color-purple: #a78bfa;
    --main-card-color-secondary: #32435e;
    --main-gray: #64748a;
    --bg-dropdown-list: #1b1c1f;
    --text-default: #28a0f0;
    --border-radius-sm: 0.4rem;
    --font-sm: 1.4rem;
    --font-base: 1.5rem;
    --font-md: 1.6rem;
    --font-lg: 2.4rem;
    --font-xl: 3.4rem;
    --font-small: 0.8rem;
    --font-very-small: 0.7rem;
    --bg-linear-active: linear-gradient(to right, #0c7b93, #00a8cc);
}

input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    /* background-color: var(--bg-card); */
    /* Not removed via appearance */
    margin: 0px;
    padding: 0px;
    font: inherit;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid var(--main-gray);
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    cursor: pointer;
    display: grid;
    place-content: center;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #fff;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

input[type="checkbox"]:disabled {
    --form-control-color: var(--form-control-disabled);

    color: var(--form-control-disabled);
    cursor: not-allowed;
}

.drop-shadow {
    filter: drop-shadow(0 0 8px #268fd1);
}

.card {
    background-color: var(--bg-card);
}

.bg-red {
    background-color: var(--main-short);
}

.bg-green {
    background-color: var(--main-long);
}

.bg-yellow {
    background-color: var(--main-yellow);
}

.bg-hover:hover {
    background-color: var(--text-default);
    border-radius: 3px;
}

.dotted-underline {
    border-bottom: 1px dotted;
}

#bg {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: #040818;
    pointer-events: none;
    z-index: -1;
}

.bg {
    position: absolute;
    height: 100%;
    width: 100%;
    filter: blur(158px);
    -webkit-filter: blur(158px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    z-index: -1;
    pointer-events: none;
    opacity: 0.3;
}

.bg-errorLight {
    background-color: rgba(255, 82, 82, 0.2);
}

.bg-successLight {
    background-color: rgba(15, 198, 121, 0.3);
}

.bg-pending {
    background-color: rgba(181, 159, 31, 0.3);
}

.bg-default {
    background-color: var(--text-default);
}

.bg-operacity {
    background: rgba(0, 0, 0, 8);
}

.text-default {
    color: var(--text-default);
}

.text-success {
    color: var(--main-long);
}

.text-pending {
    color: var(--main-yellow);
}

.text-error {
    color: var(--main-short);
}

.green-color {
    color: var(--text-color-green);
}

.purple-color {
    color: var(--text-color-purple);
}

.eth-color {
    color: var(--text-color-purple) !important;
}

.btc-color {
    color: #f79d2f !important;
}

.btc-border {
    border-color: #f79d2f !important;
}

.usdc-color {
    color: #2775ca !important;
}

.dominance-color {
    color: #369bee;
}

.eth-border {
    border-color: var(--text-color-purple) !important;
}

.eth-linear {
    background-image: linear-gradient(120deg, #575b69, #a8b9f6) !important;
}

.btc-linear {
    background-image: linear-gradient(120deg, #53596d, #e3a150) !important;
}

.usdc-linear {
    background-image: linear-gradient(120deg, #53596d, #2775ca) !important;
}

.border {
    border: 1px solid rgba(163, 165, 196, 0.2);
}

.border-green {
    border: 1px solid var(--main-long);
}

.border-red {
    border: 1px solid var(--main-short);
}

.border-yellow {
    border: 1px solid var(--main-yellow);
}

.border-default {
    border: 1px solid var(--text-default);
}

.border-t {
    border-top: 1px solid rgba(163, 165, 196, 0.2);
}

.border-b {
    border-bottom: 1px solid rgba(163, 165, 196, 0.2);
}

.border-l {
    border-left: 1px solid rgba(163, 165, 196, 0.2);
}

.border-r {
    border-right: 1px solid rgba(163, 165, 196, 0.2);
}

.usdc-border {
    border-color: #2775ca !important;
}

.border-binance {
    border: 1px solid #ffd42d !important;
}

.bg-binance {
    background-color: #ffd42d;
}

.blur-bg {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.625rem 1.25rem 0 rgba(113, 113, 166, 0.2);
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px) !important;
    z-index: 1;
}

.blur-left {
    position: absolute;
    width: 120px;
    height: 90px;
    left: 0;
    top: -60px;
    filter: blur(85px);
    -webkit-filter: blur(85px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    z-index: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.blur-right {
    position: absolute;
    width: 120px;
    height: 90px;
    right: 0;
    top: -60px;
    filter: blur(85px);
    -webkit-filter: blur(85px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    z-index: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.hover-text-default:hover {
    color: var(--text-default);
}

.hover-opacity:hover {
    opacity: 0.6 !important;
}

.active-kucoin {
    border: 1px solid #20a287 !important;
}

.active-coinbase {
    border: 1px solid #0145d4 !important;
}

.active-bybit {
    border: 1px solid #f7a600 !important;
}

.active-binance {
    border: 1px solid #ffd42d !important;
}

.active-okx {
    border: 1px solid #fff !important;
}

.text-defaultlink {
    border: 1px solid #122771 !important;
}

.w-1px {
    width: 1px !important;
}

.w-30px {
    width: 6rem;
}

.vh-full-screen {
    height: 93vh;
    overflow-y: scroll;
}

.vh-full-screen::-webkit-scrollbar {
    display: none;
}

.vh-7 {
    height: 7vh;
}

.vh-3 {
    height: 3vh;
}

.vh-8 {
    height: 8vh;
}

.h-1px {
    height: 1px;
}

.vh-10 {
    height: 10vh;
}

.vh-12 {
    height: 12vh;
}

.vh-15 {
    height: 15vh;
}

.vh-18 {
    height: 19vh;
}

.vh-20 {
    height: 20vh;
}

.vh-25 {
    height: 25vh;
}

.vh-30 {
    height: 30vh;
}

.vh-40 {
    height: 40vh;
}

.vh-60 {
    height: 60vh;
}

.vh-65 {
    height: 65vh;
}

.vh-70 {
    height: 70vh;
}

.vh-75 {
    height: 75vh;
}

.vh-80 {
    height: 80vh;
}

.vh-83 {
    height: 83vh;
}

.vh-90 {
    height: 90vh;
}

#bg .bg div {
    position: absolute;
    top: -40px;
    width: 400px;
    height: 400px;
    border-radius: 50%;
}

#bg .bg div.orange-bg {
    background: #0c7b93;
    left: calc(50% - 560px);
}

#bg .bg div.blue-bg {
    background: #bbbe24;
    left: calc(50% - 200px);
}

#bg.bg div.white-bg {
    background: #fff;
    left: calc(50% - 200px);
}

.shadow {
    box-shadow: rgba(113, 113, 166, 0.2) 0px 7px 29px 0px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #f5f5f5;
    overflow: hidden;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #d62929;
}

.close-dropdown {
    opacity: 0;
    transform: scaleY(0);
    animation: growUp 300ms ease-in-out forwards;
    transform-style: flat;
    transform-origin: top;
}

.open-dropdown {
    opacity: 1;
    animation: growDown 300ms ease-in-out forwards;
    transform-style: flat;
    transform-origin: top center;
}

.text-title-section {
    display: inline-flex;
    background-image: linear-gradient(
        150deg,
        #4f7d89,
        #4f7d89 15%,
        #2193a7 25%,
        #189fd9,
        #292986 50%,
        #1e84df 80%,
        #1e84df 100%
    );
    font-weight: 400;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.font-small {
    font-size: var(--font-small);
}

.font-very-small {
    font-size: var(--font-very-small);
}

.bg-dropdown {
    background: var(--input-bg-color);
}

.input-shadow {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.active-default {
    background: var(--text-default);
    color: #ffffff;
}

.bg {
    box-shadow: rgba(113, 113, 166, 0.2) 0px 15px 20px -15px;
    width: 100%;
    background: url("../img/bg/header_bg.png") right top no-repeat;
}

.bg-orange {
    background: #fdc830 !important;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f37335, #fdc830) !important;
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #f37335, #fdc830) !important;
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera W12+, Safari 7+ */
}

.heroicon path {
    fill: #00a8cc !important;
}

svg path {
    fill: #ffffff !important;
}

ul {
    list-style: none;
}

/* Track */
::-webkit-scrollbar-track {
    background: #1c1c1c;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #383838;
    border-radius: 1.5rem;
    border: 1px solid #212121;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #4a4a4a;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

input,
.input {
    color: white;
    outline: none;
    background: none;
    border: none;
    padding: 1.4rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: "Poppins", sans-serif;
}

.disabled {
    pointer-events: none !important;
    cursor: not-allowed !important;
}

.active-tab-default {
    color: var(--text-default);
    border-bottom: 1px solid var(--text-default);
    background: rgba(127, 212, 130, 0.05);
}

.checkbox:checked:before {
    background-color: green;
}

.block {
    display: block !important;
}

.hidden {
    display: none !important;
}

.rotate180 {
    transform: rotate(180deg);
}

/* effect css */
.fade-in {
    opacity: 0;
    transition: opacity 0.5s ease-in;
}

.fade-in.visible {
    opacity: 1;
}

.translateY-effect {
    transform: translateY(200%);
    opacity: 0;
    transition: all 0.3s ease-in;
}

.translateY-effect.active {
    transform: translateY(0);
    opacity: 1;
}

.leftToRight-effect {
    transform: translateX(-200%);
    opacity: 0;
    transition: all 0.3s ease-in;
}

.leftToRight-effect.active {
    transform: translateX(0);
    opacity: 1;
}

.toast-in-right {
    animation: toast-in-right 0.5s;
}

.z-1000 {
    z-index: 1000;
}

@keyframes toast-in-right {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}
