.slider-custom .rc-slider-track {
    background: transparent;
}

.slider-custom .rc-slider-dot {
    width: 3px;
    border: none;
}

.slider-custom .alert-badge {
    background-color: rgba(243, 255, 108, 0.1);
    border-color: rgb(243, 255, 108);
}

.slider-custom .rc-slider-dot:nth-child(1) {
    background-color: var(--main-long);
}

.slider-custom .rc-slider-dot:nth-child(2) {
    background-color: #f3ff6c;
}

.slider-custom .rc-slider-dot:nth-child(3) {
    background-color: #f3ff6c;
}

.slider-custom .rc-slider-dot:nth-child(4) {
    background-color: var(--main-short);
}

.rc-slider-handle {
    position: relative;
    background-color: transparent;
    border: none;
    z-index: 99999999999;
}

.rc-slider-handle::before {
    position: absolute;
    white-space: pre;
    display: inline;
    top: 5%;
    left: 50%;
    font-size: 12px !important;
    transform: translate(-50%);
    content: attr(aria-label);
    font-weight: 500;
    z-index: 999999999;
}

.slider-custom .rc-slider-tooltip-placement-right {
    margin-right: -50px;
}

.slider-custom .rc-slider-mark-text,
.slider-custom .rc-slider-mark-text-active {
    color: #fff !important;
    opacity: 0.5;
}

.slider-custom .rc-slider-mark-text:hover,
.slider-custom .rc-slider-mark-text-active:hover {
    opacity: 1;
}

.slider-custom .group-tooltip .tooltip {
    display: none;
}

.slider-custom .group-tooltip:hover .tooltip {
    display: block;
    position: absolute;
    top: -2rem;
    left: 0.5rem;
    font-size: small;
}
