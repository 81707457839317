.banner-db {
    position: relative;
    height: 60vh;
    width: 100%;
}

.banner-db .banner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
}

.animation-container {
    position: relative;
    overflow: hidden;
}

.animation-container .lightning-container {
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    transform: translateY(-50%);
}

.animation-container .lightning-container .lightning {
    position: absolute;
    display: block;
    height: 12px;
    width: 12px;
    border-radius: 12px;
    transform-origin: 6px 6px;
    -webkit-animation-name: woosh;
    animation-name: woosh;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

.animation-container .lightning-container .lightning.white {
    background-color: white;
    box-shadow: 0px 50px 50px 0px rgba(255, 255, 255, 0.3);
}

.animation-container .lightning-container .lightning.blu {
    background-color: #2193a7;
    box-shadow: 0px 50px 50px 0px #2193a7;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.animation-container .boom-container {
    position: absolute;
    display: flex;
    width: 80px;
    height: 80px;
    text-align: center;
    align-items: center;
    transform: translateY(-50%);
    left: 0;
    top: 0;
}

.animation-container .boom-container .shape {
    display: inline-block;
    position: relative;
    opacity: 0;
    transform-origin: center center;
}

.animation-container .boom-container .shape.triangle {
    width: 0;
    height: 0;
    border-style: solid;
    transform-origin: 50% 80%;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    margin-left: -15px;
    border-width: 0 2.5px 5px 2.5px;
    border-color: transparent transparent #42e599 transparent;
    -webkit-animation-name: boom-triangle;
    animation-name: boom-triangle;
}

.animation-container .boom-container .shape.triangle.big {
    margin-left: -25px;
    border-width: 0 5px 10px 5px;
    border-color: transparent transparent #fade28 transparent;
    -webkit-animation-name: boom-triangle-big;
    animation-name: boom-triangle-big;
}

.animation-container .boom-container .shape.disc {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #d15ff4;
    -webkit-animation-name: boom-disc;
    animation-name: boom-disc;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.animation-container .boom-container .shape.circle {
    width: 20px;
    height: 20px;
    -webkit-animation-name: boom-circle;
    animation-name: boom-circle;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    border-radius: 100%;
    margin-left: -30px;
}

.animation-container .boom-container .shape.circle.white {
    border: 1px solid white;
}

.animation-container .boom-container .shape.circle.big {
    width: 40px;
    height: 40px;
    margin-left: 0px;
}

.animation-container .boom-container .shape.circle.big.white {
    border: 2px solid white;
}

.animation-container .boom-container .shape:after {
    background-color: rgba(178, 215, 232, 0.2);
}

.animation-container .boom-container .shape.triangle,
.animation-container .boom-container .shape.circle,
.animation-container .boom-container .shape.circle.big,
.animation-container .boom-container .shape.disc {
    -webkit-animation-delay: 0.38s;
    animation-delay: 0.38s;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
}

.animation-container .boom-container .shape.circle {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.animation-container .boom-container.second {
    left: 30%;
    top: 20%;
}

.animation-container .boom-container.third {
    left: 60%;
    top: 45%;
}

.animation-container .boom-container.second .shape.triangle,
.animation-container .boom-container.second .shape.circle,
.animation-container .boom-container.second .shape.circle.big,
.animation-container .boom-container.second .shape.disc {
    -webkit-animation-delay: 1.9s;
    animation-delay: 1.9s;
}

.animation-container .boom-container.second .shape.circle {
    -webkit-animation-delay: 2.15s;
    animation-delay: 2.15s;
}

@-webkit-keyframes woosh {
    0% {
        width: 12px;
        transform: translate(0px, 0px) rotate(-35deg);
    }

    15% {
        width: 50px;
    }

    30% {
        width: 12px;
        transform: translate(214px, -150px) rotate(-35deg);
    }

    30.1% {
        transform: translate(214px, -150px) rotate(46deg);
    }

    50% {
        width: 110px;
    }

    70% {
        width: 12px;
        transform: translate(500px, 150px) rotate(46deg);
    }

    70.1% {
        transform: translate(500px, 150px) rotate(-37deg);
    }

    85% {
        width: 50px;
    }

    100% {
        width: 12px;
        transform: translate(700px, 0) rotate(-37deg);
    }
}

@keyframes woosh {
    0% {
        width: 12px;
        transform: translate(0px, 0px) rotate(-35deg);
    }

    15% {
        width: 50px;
    }

    30% {
        width: 12px;
        transform: translate(214px, -150px) rotate(-35deg);
    }

    30.1% {
        transform: translate(214px, -150px) rotate(46deg);
    }

    50% {
        width: 110px;
    }

    70% {
        width: 12px;
        transform: translate(500px, 150px) rotate(46deg);
    }

    70.1% {
        transform: translate(500px, 150px) rotate(-37deg);
    }

    85% {
        width: 50px;
    }

    100% {
        width: 12px;
        transform: translate(700px, 0) rotate(-37deg);
    }
}

@-webkit-keyframes boom-circle {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 1;
    }

    30% {
        opacity: 0;
        transform: scale(3);
    }
}

@keyframes boom-circle {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 1;
    }

    30% {
        opacity: 0;
        transform: scale(3);
    }
}

@-webkit-keyframes boom-triangle-big {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 1;
    }

    40% {
        opacity: 0;
        transform: scale(2.5) translate(50px, -50px) rotate(360deg);
    }
}

@keyframes boom-triangle-big {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 1;
    }

    40% {
        opacity: 0;
        transform: scale(2.5) translate(50px, -50px) rotate(360deg);
    }
}

@-webkit-keyframes boom-triangle {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 1;
    }

    30% {
        opacity: 0;
        transform: scale(3) translate(20px, 40px) rotate(360deg);
    }
}

@keyframes boom-triangle {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 1;
    }

    30% {
        opacity: 0;
        transform: scale(3) translate(20px, 40px) rotate(360deg);
    }
}

@-webkit-keyframes boom-disc {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 1;
    }

    40% {
        opacity: 0;
        transform: scale(2) translate(-70px, -30px);
    }
}

@keyframes boom-disc {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 1;
    }

    40% {
        opacity: 0;
        transform: scale(2) translate(-70px, -30px);
    }
}

@media (max-width: 768px) {
    .banner-db {
        height: 90vh;
    }
}

@media (min-width: 768px) {
    .banner-container .item {
        width: 280px !important;
        margin-right: 20px;
    }

    .banner-container .item img {
        max-width: fit-content;
    }
}
