.dd-token {
    width: 100px;
    position: relative;
}

.dd-token-label {
    font-size: 14px;
    padding: 1px 3px;
}

.dd-token-list {
    position: absolute;
    top: 2.5rem;
    right: 0;
    width: 200px;
    background: var(--bg-dropdown-list);
    transition: all 0.3s ease-in-out;
    z-index: 9999999;
}

.dd-token-item {
    cursor: pointer;
}

.dd-token-item:hover {
    background: var(--arbitrum-blue);
    border-radius: 3px;
}

.dd-token .close {
    opacity: 0;
    transform: scaleY(0);
    animation: growUp 300ms ease-in-out forwards;
    transform-style: flat;
    transform-origin: top;
}

.dd-token .open {
    opacity: 1;
    animation: growDown 300ms ease-in-out forwards;
    transform-style: flat;
    transform-origin: top center;
}

@keyframes growDown {
    0% {
        transform: scaleY(0);
    }

    80% {
        transform: scaleY(1.1);
    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes growUp {
    0% {
        transform: scaleY(1);
    }

    80% {
        transform: scaleY(1.1);
    }

    100% {
        transform: scaleY(0);
    }
}
