.vault-detail .bg-eth-banner {
    background: linear-gradient(96.84deg, rgba(98, 126, 234, 0.16) 1.04%, rgba(98, 126, 234, 0.027) 98.99%);
    padding: 40px 0px;
    overflow: hidden;
}

.vault-detail .bg-btc-banner {
    background: linear-gradient(96.84deg, rgba(137, 107, 7, 0.16) 1.04%, rgba(240, 185, 11, 0.027) 98.99%);
    padding: 40px 0px;
    overflow: hidden;
}

.vault-detail .bg-usdc-banner {
    background: linear-gradient(96.84deg, rgba(62, 115, 196, 0.16) 1.04%, rgba(62, 115, 196, 0.027) 98.99%);
    padding: 40px 0px;
    overflow: hidden;
}

.vault-detail .rank .max {
    background-color: #3d3d47;
}

.vault-detail .card-deposit-vault .card-header {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.vault-detail .deposit-card .active {
    background: #6d89ed;
}
