.input-custom {
    position: relative;
}

.input-custom .group-tooltip .tooltip {
    display: none;
}

.input-custom .group-tooltip:hover .tooltip {
    display: block;
    position: absolute;
    top: -2rem;
    left: 0.5rem;
    font-size: small;
}

.input-custom .balance {
    position: absolute;
    right: 0;
    top: 0;
}

.input-custom .max-btn {
    position: absolute;
    top: 45%;
    right: 10px;
    border-color: var(--arbitrum-blue);
    color: var(--arbitrum-blue);
}
